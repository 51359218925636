<template>
    <div class="Coupon">
        <div class="item-list">
             <label>兑换码名称:</label>
             <div class="item-content">
                 <a-input v-model="codePackageName" placeholder="请输入兑换码名称" :disabled="read_only"/>
             </div>
         </div>
         <div class="item-list">
             <label>发放数量:</label>
             <div class="item-content">
                 <a-input v-model="sendCount" placeholder="请输入发放数量" disabled/>
             </div>
         </div>
         <div class="item-list">
             <label>使用时间:</label>
             <div class="item-content">
                 <!-- <a-range-picker :value="[startTime,expireTime]" format="YYYY-MM-DD" :placeholder="['开始时间','结束时间']" allowClear @change="onChangeUseTime" /> -->
                 <a-date-picker disabled :disabled-date="disabledDate" :value="startTime" @change="onChangeUseTime"/>
                 -
                 <a-date-picker :disabled="read_only" :disabled-date="disabledDate" :value="expireTime" @change="onChangeUseTime"/>
             </div>
         </div>
         <div class="item-list">
             <label>发放渠道:</label>
             <div class="item-content">
                 <a-select :value="channelText" style="width: 200px" @change="onChangeChannel" :disabled="read_only">
                  <a-select-option v-for="(item,index) in channelList" :value="item.simple_id">{{item.simple_name}}</a-select-option>
                </a-select>
             </div>
         </div>
         <div class="item-list">
             <label>发放内容:</label>
             <div class="item-content">
                 <a-tabs :activeKey="defaultKey" @change="_get_tabs_data" style="width: 600px;">
                  <a-tab-pane key="3" tab="AI课" :disabled="defaultKey == 3 ? false : true">
                    <a-tabs :activeKey="aiSelectIndex" @change="aiChange">
                      <a-tab-pane v-for="(item, index) in aiCourseSetting" :key="index" :tab="item.title" :disabled="index == aiSelectIndex ? false : true">
                          <div class="aibus" v-for="(item1,index1) in item.settingStageResList">
                              <a-checkbox :value="item1.mu" @change="onChangeCategoryCheck($event,index,index1,0)" :checked="item1.checked">{{item1.stage_title}}</a-checkbox>
                              <span>{{item1.selectedLessonCount}}/{{item1.course_num}}</span>
                              <a-tag color="blue" @click="_select_lesson_ai(item1,index,index1,0)" v-if="!read_only">编辑</a-tag>
                          </div>
                      </a-tab-pane>
                    </a-tabs>
                  </a-tab-pane>
                  <a-tab-pane key="1" tab="核心课" :disabled="defaultKey == 1 ? false : true">
                    <a-tabs :activeKey="coreCourseSelectIndex" tab-position="top" @change="_core_category_tab">
                      <a-tab-pane v-for="(item,index) in coreCourseList" :key="index.toString()" :tab="item.title">
                          <div class="aibus" v-for="(item1,index1) in item.categoryDetailResList">
                              <a-checkbox :value="item1.id" @change="onChangeCategoryCheck($event,index,index1,1)" :checked="item1.checked">{{item1.title}}</a-checkbox>
                              <span>{{item1.selectedLessonCount}}/{{item1.all_lesson_count}}</span>
                              <a-tag color="blue" @click="_select_lesson_core(item1,index,index1,1)" v-if="!read_only">编辑</a-tag>
                          </div>
                      </a-tab-pane>
                    </a-tabs>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="拓展" :disabled="defaultKey == 2 ? false : true">
                        <a-radio-group v-model="expandCourse" @change="onChangExpandCourse">
                            <a-radio style="display: block;height: 30px;line-height: 30px;" v-for="(item,index) in expandCourseList" :value="item.lecture_unique" :checked="item.checked">{{item.title}}</a-radio>
                        </a-radio-group>
                  </a-tab-pane>
                </a-tabs>
             </div>
         </div>

         <div class="btn-list" style="margin-top: 100px;" v-if="!read_only">
                <a-button type="primary" @click="do_save">保存</a-button>
         </div>

         <a-modal v-model="lessonState" :title="lessonTitle" @ok="modalHandleOk" @cancel="modelCancel">
              <div class="lesson-list">
                  <div class="lesson-all">
                      <a-checkbox :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>
                  </div>
                  <a-checkbox-group v-model="lesson_select" :options="lesson_checkbox_option" @change="onChangeCheckbox" />
              </div>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name:'QmExchange_edit',
    data(){
        return{

            aiCourseSetting:[],
            lessonState:false,
            lessonTitle:'选择课程',
            lesson_mu:'',
            lesson_checkbox_option:[],
            lesson_select:[],
            checkAll: false,
            category_check:false,
            _tmp_index_1:null,
            _tmp_index_2:null,
            _tmp_type_value:null,
            aiCourseList:[],

            coreCourseList:[],
            coreCategoryList:[],
            expandCourseList:[],
            expandCourse:[],

            codeCatogories:[],

            categoryId:'',
            categoryTitle:'',


            codePackageName: '',
            sendCount: 0,
            startTime:'',
            expireTime:'',
            sendChannel: 0,
            exchangeType: 0,
            courseTitle:'',
            courseId:'',

            channelList:[],
            activeKey:null,
            aiCourseIndex:null,
            aiSelectIndex:0,
            _codePackageId:'',
            defaultKey:null,
            coreCourseSelectIndex:0,
            selectedCount:0,

            channelText:'',
            lectureModelList:[],
            read_only:false,
        }
    },
    props: ['codePackageId'],
    watch: {
    codePackageId: {
        immediate: true,
        handler (val) {
            let that = this;
            that._codePackageId = val.codePackageId;
            that.read_only = val.read_only;
            that._getSendChannel();
            that._getData();
            
          },
          deep: true
        }
    },
    created(){
        
    },
    mounted(){
         
    },
    methods:{
     moment,
     _getData(){
        let that = this;
        that._init_codeCatogories();
        that.$message.loading('数据加载中...');
        that.$axios.get(8000137, {codePackageId:that._codePackageId},res => {
            if(res.data.code==1){
                let _d = res.data.data;
                let tmplist = [];
                that.codePackageName = _d.code_package_name;
                that.sendChannel = _d.send_channel;
                that.sendCount = _d.send_count;
                that.channelText = that._getChannel(that.sendChannel);
                that.startTime = moment(_d.start_time);
                that.expireTime = moment(_d.expire_time);
                that.selectedCount = _d.exchange_lesson_count;
                that.exchangeType = _d.exchange_type;

                if (_d.exchange_type == 1) {
                    that.defaultKey = '1';
                    that.coreCourseList = _d.courseDetailResList;
                    that.coreCourseList.map(function(item,index){
                        if (_d.course_id == item.course_unique) {
                            that.coreCourseSelectIndex = index.toString();
                            that._set_course_value(item.course_unique,item.title);
                        }
                        item.categoryDetailResList.map(function(sta_item,sta_index){
                            sta_item.selectedCount = 0;
                            sta_item.lessonlist = [];
                            sta_item.packageDetailModelList.map(function(p_item,p_index){
                                that.set_category_value(p_item.category_id,p_item.category_title);
                                tmplist.push(p_item.package_lesson_id);
                            });
                        });
                    });
                    that._set_codeCatogories(tmplist,false);
                } else if (_d.exchange_type == 4 || _d.exchange_type == 5) {
                    that.defaultKey = '3';
                    that.aiCourseSetting = _d.aiCourseSettingDetailResList;
                    that.aiCourseSetting.map(function(item,index){
                        item.selectedCount = _d.exchange_lesson_count;
                        if (_d.course_id == item.mu) {
                            that.aiSelectIndex = index;
                        }
                        item.settingStageResList.map(function(sta_item,sta_index){
                            sta_item.selectedCount = 0;
                            sta_item.lessonlist = [];
                            sta_item.packageDetailModelList.map(function(p_item,p_index){
                                that.set_category_value(p_item.category_id,p_item.category_title);
                                tmplist.push(p_item.package_lesson_id);
                            });
                        });
                    });
                    that.aiCourseIndex = 0;
                    that._set_course_value(
                        that.aiCourseSetting[0].mu,
                        that.aiCourseSetting[0].title
                    );
                    that._set_codeCatogories(tmplist,false);
                } else if (_d.exchange_type == 2) {
                    that.defaultKey = '2';
                    that.expandCourse = _d.lectureModelList[0].lecture_unique;
                    that.codeCatogories.push({
                        categoryId: that.expandCourse,
                        categoryTitle: _d.course_title,
                        lessons: []
                    })
                    that._getExpandCourse();
                }
                console.log(that.defaultKey);
            }else{
                
            }
        });
     },
     _getChannel(value){
        let len = this.channelList.length;
        let str = '';
        for (var i = 0; i < len; i++) {
            if (value == this.channelList[i].simple_id) {
                str = this.channelList[i].simple_name;
                break;
            }
        }
        return str;
     },
     disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
     },
     _get_tabs_data(activeKey){
        this.activeKey = activeKey;
        this._init_codeCatogories();
        // switch (parseInt(activeKey)) {
        //     case 3:
        //         this._getAICourseSetting();
        //         break;
        //     case 1:
        //         this._getCoreCourse();
        //         break;
        //     case 2:
        //         this._getExpandCourse();
        //         break;
        //     default:
        // }
     },
     _getAICourseSetting(){
        this.$message.loading('数据加载中...');
        this.$axios.post(8000128, {
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.aiCourseSetting = res.data.data;
                this.aiCourseSetting.map(function(item,index){
                    item.settingStageResList.map(function(sta_item,sta_index){
                        sta_item.selectedCount = 0;
                        sta_item.lessonlist = [];
                    });
                });
                this.aiCourseIndex = 0;
                this._set_course_value(
                    this.aiCourseSetting[0].mu,
                    this.aiCourseSetting[0].title
                );
            }else{
                this.aiCourseSetting = [];
            }
            this.$message.destroy()
        });
     },
     _getCoreCourse(){
        this.$axios.post(8000130, {
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.coreCourseList = res.data.data;
                this._core_category_tab(0);
            }else{
                this.coreCourseList = [];
            }
        });
     },
     _getCoreCategory(courseUnique){
        this.$axios.post(8000131, {
            courseUnique:courseUnique,
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.coreCategoryList = res.data.data;
                this.coreCategoryList.map(function(item,index){
                    item.selectedCount = 0;
                    item.lessonlist = [];
                });
            }else{
                this.coreCategoryList = [];
            }
        });
     },
     _core_category_tab(index){
        console.log(index);
        this.codeCatogories = [];
        this.coreCourseSelectIndex = index;
        this._init_codeCatogories();
        // this._getCoreCategory(this.coreCourseList[index].course_unique);
        this._set_course_value(
            this.coreCourseList[index].course_unique,
            this.coreCourseList[index].title
        );
     },
     _getExpandCourse(){
        this.$axios.post(8000132, {
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.expandCourseList = res.data.data;
            }else{
                this.expandCourseList = [];
            }
        });
     },
     _select_lesson_ai(_item,i,j,type){
        this.lessonState = true;
        this.lessonTitle = '选择课程-'+_item.stage_title;
        this.set_category_value(_item.mu,_item.stage_title);
        this._set_tmp_value(i,j,type);
        this.aiCourseIndex = i;

        this.$axios.post(8000129, {mu: _item.mu},res => {
            if(res.data.code==1){
                this.aiCourseList = res.data.data;
                let tmplist = [];

                //使用已经存在的
                if (this.codeCatogories) {
                    this.codeCatogories.forEach((item,index) => {
                        if (_item.mu == item.categoryId) {
                            item.lessons.forEach((sta_item,sta_index) => {
                                tmplist.push(sta_item.lessonId);
                            });
                        }
                    });
                } else {
                    _item.packageDetailModelList.forEach((item,index) => {
                        tmplist.push(item.package_lesson_id);
                    });
                }

                this._set_select_lesson(tmplist);
                this.checkAll = tmplist.length === this.aiCourseList.length;
                this.aiCourseList.forEach((item, index) => {
                    let tmp = {};
                    tmp.label = `第${index + 1}节`;
                    tmp.value = item.mu;
                    this.lesson_checkbox_option.push(tmp);
                })
            }else{
                this.aiCourseList = [];
            }
        });
     },
     _select_lesson_core(_item,i,j,type){
        this.aiCourseIndex = null;
        this.lessonState = true;
        this.lessonTitle = '选择课程-'+_item.title;
        this.set_category_value(_item.category_unique,_item.title);
        this._set_tmp_value(i,j,type);

        this.$axios.post(8000133, {categoryId: _item.category_unique},res => {
            if(res.data.code==1){
                let _data = res.data.data;
                let tmplist = [];
                console.log(this.codeCatogories);
                //使用已经存在的
                if (this.codeCatogories) {
                    this.codeCatogories.forEach((item,index) => {
                        if (_item.category_unique == item.categoryId) {
                            item.lessons.forEach((sta_item,sta_index) => {
                                tmplist.push(sta_item.lessonId);
                            });
                        }
                    });
                } else {
                    _item.packageDetailModelList.forEach((item,index) => {
                        tmplist.push(item.package_lesson_id);
                    });
                }
                
                this._set_select_lesson(tmplist);
                this.checkAll = tmplist.length === _data.length;
                _data.forEach((item, index) => {
                    let tmp = {};
                    tmp.label = `第${index + 1}节`;
                    tmp.value = item.lesson_unique;
                    this.lesson_checkbox_option.push(tmp);
                })

            }else{
                this.lesson_checkbox_option = [];
            }
        });
     },
     _set_tmp_value(i,j,type){
        this._tmp_index_1 = i;
        this._tmp_index_2 = j;
        this._tmp_type_value = type;
     },
     _set_select_count(count,lesson_list){
        if (this._tmp_type_value == null) {return;}
        let i = this._tmp_index_1;
        let j = this._tmp_index_2;
        switch (this._tmp_type_value) {
            case 0:
                this.aiCourseSetting[i].settingStageResList[j].selectedLessonCount = count;
                this.aiCourseSetting[i].settingStageResList[j].lessonlist = lesson_list;
                break;
            case 1:
                this.coreCourseList[i].categoryDetailResList[j].selectedLessonCount = count;
                this.coreCourseList[i].categoryDetailResList[j].lessonlist = lesson_list;
                break;
            default:
                break;
        }
     },
     modalHandleOk(e){
        this.lessonState = false;
        this._clear_lesson();
     },
     modelCancel(e){
        // this.codeCatogories = [];
        this._set_tmp_value(null,null,null);
        this._clear_lesson();
     },
     onChangeCheckbox(checkedList){
        let _ids = this._get_lesson_id_all();
        this.checkAll = checkedList.length === _ids.length;
        this._set_codeCatogories(checkedList);
     },
     _set_codeCatogories(checkedList,count_set = true){
        if (checkedList.length > 0) {
            if (count_set) {
                this._set_select_count(checkedList.length,checkedList);
            }
            let codeCatogories;
            if (this.codeCatogories.length > 0) {

                let _tmp;
                let _lst = [];
                this.codeCatogories.forEach((item,index) => {
                    _lst.push(item.categoryId);
                })

                //不存在就创建
                if (_lst.indexOf(this.categoryId) == -1) {
                    _tmp = {
                        categoryId:this.categoryId,
                        categoryTitle:this.categoryTitle,
                        lessons:[]
                    };
                }
                
                if (_tmp) {this.codeCatogories.push(_tmp);}
                
                this.codeCatogories.forEach((item,index) => {
                    if (item.categoryId == this.categoryId) {
                        item.lessons = [];
                        for (var j = 0; j < checkedList.length; j++) {
                            let tmp = {
                                lessonCreateState:1,
                                lessonId:checkedList[j]
                            };
                            item.lessons.push(tmp);
                        }
                    }
                })
            } else {
                codeCatogories = {
                    categoryId:this.categoryId,
                    categoryTitle:this.categoryTitle,
                    lessons:[]
                };
                for (var i = 0; i < checkedList.length; i++) {
                    let tmp = {
                        lessonCreateState:1,
                        lessonId:checkedList[i]
                    };
                    codeCatogories.lessons.push(tmp);
                }
                this.codeCatogories.push(codeCatogories);
            }      
        } else {
            this._clear_codeCatogories_value();
        }
     },
     _clear_codeCatogories_value(){
        let i = this._tmp_index_1;
        let j = this._tmp_index_2;

        //删除
        for (var n = 0; n < this.codeCatogories.length; n++) {
            if (this.codeCatogories[n].categoryId == this.categoryId) {this.codeCatogories.splice(n,1);}
        }

        switch (this._tmp_type_value) {
            case 0:
                this.aiCourseSetting[i].settingStageResList[j].selectedLessonCount = 0;
                this.aiCourseSetting[i].settingStageResList[j].lessonlist = [];
                break;
            case 1:
                this.coreCourseList[i].categoryDetailResList[j].selectedLessonCount = 0;
                this.coreCourseList[i].categoryDetailResList[j].lessonlist = [];
                break;
            default:
                break;
        }
     },
     onCheckAllChange(e){

        if (e.target.checked) {
            this.lesson_select = this._get_lesson_id_all();
            this.category_check = true;
            this._set_codeCatogories(this.lesson_select);
        } else {
            this.lesson_select = [];
            this.category_check = false;
            this._clear_codeCatogories_value();
        }

        this.checkAll = e.target.checked;
     },
     aiChange(activeKey){
        this._set_course_value(
            this.aiCourseSetting[activeKey].mu,
            this.aiCourseSetting[activeKey].title
        );
        this._clear_lesson();
        this._init_codeCatogories();
     },
     _set_select_lesson(list){
        this.lesson_select = list;
     },
     _clear_lesson(){
        this.category_check = this.checkAll = false;
        this.lesson_select = [];
        this.lesson_checkbox_option = [];
     },
     _get_lesson_id_all(){
        if (this.lesson_checkbox_option) {
            let _lst = [];
            this.lesson_checkbox_option.forEach((item,index) => {
                _lst.push(item.value);
            });
            return _lst;
        }
        return [];
     },
     onChangeCategoryCheck(e,i,j,type){
        this.category_check = e.target.checked;
        switch (type) {
            case 0:
                this.aiCourseSetting[i].settingStageResList[j].checked = e.target.checked;
                break;
            case 1:
                this.coreCourseList[i].categoryDetailResList[j].checked = e.target.checked;
                break;
            default:
                break;
        }
     },
     prevClick(e){
        console.log(e);
     },
     nextClick(e){
        console.log(e);
     },
     onChangExpandCourse(e){
        let _value = e.target.value;
        this._init_codeCatogories();
        for (var i = 0; i < this.expandCourseList.length; i++) {
            if (this.expandCourseList[i].lecture_unique == _value) {
                this.codeCatogories.push({
                    categoryId: _value,
                    categoryTitle: this.expandCourseList[i].title,
                    lessons: []
                })
            }
        }
     },
     _init_codeCatogories(){
        this.codeCatogories = [];
     },
     set_category_value(categoryId,categoryTitle){
        this.categoryId = categoryId;
        this.categoryTitle = categoryTitle;
     },
     do_save(){
        let that = this;
        let params = {
            package_id:that._codePackageId,
            codePackageName: that.codePackageName,
            sendCount: Number(that.sendCount),
            startTime: moment(that.startTime).format('YYYY-MM-DD'),
            expireTime: moment(that.expireTime).format('YYYY-MM-DD'),
            sendChannel: that.sendChannel,
            exchangeType: that.exchangeType,
            codeCatogories:JSON.stringify(that.codeCatogories),
            courseTitle: that.courseTitle,
            courseId: that.courseId,
        }

        that.$axios.post(8000138, params,res => {
            if (res.data.code == 1) {
                this.$emit('fathermethod', 1);
            } else {
                that.$message.error(res.data.message);
            }
        });

     },
     _set_course_value(courseId,courseTitle){
        this.courseId = courseId;
        this.courseTitle = courseTitle;
     },
     onChangeUseTime(value){
        if (value.length > 0) {
            this.startTime = value[0]._d;//moment(value[0]._d).format('YYYY-MM-DD');
            this.expireTime = value[1]._d;//moment(value[1]._d).format('YYYY-MM-DD');
        } else {
            this.startTime = '';
            this.expireTime = '';
        }
     },
     _getSendChannel(){
        this.$axios.post(8000134, {name: 'sendChannel'},res => {
            if(res.data.code==1){
                this.channelList = res.data.data;
            }else{
                this.channelList = [];
            }
        });
     },
     onChangeChannel(value){
        this.sendChannel = value;
     },
    },
}
</script>

<style lang="less" > 
    .Coupon{
         .item-header{
            padding: 15px 20px;
            background: #fff;
            border-radius: 4px;
            .screen-list-item{
                margin-right: 20px;
            }
            .screen-item-label{
                margin-right: 10px;
                color: #666;
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }
    .Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
    .Coupon .ant-select-selection__rendered{line-height: 34px;}
    .Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 6px;
    }

    .item-list{
        display: flex;
        overflow: hidden;
        margin-bottom: 25px;
        .item-content{
            float: right;
            width: 400px;
        }
        label{
            margin-top: 6px;
            margin-right: 15px;
        }
    }
</style>